import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "../components/footer";
import Header from "../components/header";
import SEO from "../components/seo";
import "./layout.css";
import "slick-carousel/slick/slick.css";
import "antd/dist/antd.css";
import "../assets/css/animate.css";
import "../assets/scss/style.scss";
// import axios from "axios";
// import { window } from 'browser-monads';
// import { DIZZEE_API } from "gatsby-env-variables";

const Layout = ({
  children,
  uma,
  headerColor,
  title,
  seoTitle,
  seoDes,
  location,
}) => {

  const data = useStaticQuery(graphql`
    query MyQuery {
      WordPress {
        menus {
          nodes {
            name
            slug
            id
            menuItems {
              nodes {
                label
                url
                cssClasses
                drawer_menu_apps {
                  labeldrawer
                  idmenudrawer
                  backgroundColorIcon
                }
                menu_data {
                  externalLink
                  sectionId
                  svg {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDes} />
      <Header
        headerColor={headerColor}
        title={title}
        menuData={data}
        location={location}
      />
      <main className={uma ? "inner-page" : ""}>
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
