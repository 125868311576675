import React, { Component } from "react";
// import menuicon from '../assets/images/menu-icon.svg';
import closeicon from '../assets/images/close-icon.svg';
// import { navigate } from "@reach/router";
// import CF from '../components/commonfunctions';
import DropdownButton from 'react-bootstrap/DropdownButton'
// import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import { getCookie } from "../utlis/cookieMgmt";
import { window } from 'browser-monads';
import { DIZZEE_LOGIN, DIZZEE_API, DIZZEE_LOGOUT, CLIENT_URL, AMP_API } from "gatsby-env-variables";
import { AppDrawer } from "@umg/ui-components";
import axios from "axios";
import reloadIfAuthExpired from '../services/auth';
import amplitude from 'amplitude-js';

const redirect = encodeURIComponent(CLIENT_URL);
const LOGOUT = `${DIZZEE_LOGOUT}&redirect=${redirect}`;

class Header extends Component {

  state = {
    menu: false,
    blog_drawer: {},
    menu_drawer: {},
    learn_more: {},
    show: false,
    canRender: false,
    user: {},
    valid: false,
  }
  
  handleShow = () => {
    this.setState({ show: true })
  };
  handleClose = () => {
    this.setState({ show: false })
  };

  logout = () => {
    window.location.assign(LOGOUT);
  }

  validToken = () => {
    // Start Amplitude Events
    const ampOptions = {
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      includeReferrer: true,
      trackingOptions: {
        language: true,
        city: true,
        region: true,
        dma: true,
        country: true,
        carrier: false,
        ip_address: false,
        device_manufacturer: true,
        device_model: true,
        os_name: true,
        os_version: true,
        platform: true,
        version_name: true,
      },
    };
    amplitude.getInstance().init(AMP_API, null, ampOptions);
    // End Amplitude Events
    if (getCookie("swiftTrends")) {
      axios
        .get(`${DIZZEE_API}/api/whoami?app=swiftTrends`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "swiftTrends"
          },
        })
        .then((response) => {
          // console.log('WHOAMI token status', response);
          if ((response.data && response.data.token) && response.status === 200) {
            axios
            .get(`${DIZZEE_API}/action/user/data?apps=all`, {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                application: "swiftTrends"
              },
            })
            .then((resp) => {
              if (resp.data.info === "SUCCESSFULLACTION") {
                this.setState({ user: resp.data.userInfo });
              }
            });
          }
        })
        .catch(err => {
          console.log('error', err);
          reloadIfAuthExpired(err);
        });
    }
  }

  componentDidMount() {
    this.validToken();
    this.setState({ canRender: true })
  }

  componentDidUpdate() {
    const { user } = this.state;
    const userId = user._id || null;
    // Start Amplitude Events
    amplitude.getInstance().setUserId(userId);
    const userProperties = {
      title: user.internal ? user.info.title : null,
      internal: user.internal,
      clientRole: user.info && user.info.clientRole || null
    };
    amplitude.getInstance().setUserProperties(userProperties);
    // End Amplitude Events
  }

  render() {
    let { menu, user } = this.state;
    let headerColor = this.props && this.props.headerColor;
    let title = this.props && this.props.title;
    let location = this.props && this.props.location;
    let hiddenAppsBlogById = ['ds', 'high-notes'];
    const notificationCountById = {};

    let email = user && user.email;
    let fullName = user && (user.firstName + ' ' + user.lastName);
    let displayName = user && (user.firstName && user.firstName[0]) + (user.lastName && user.lastName[0]);
    const isBrowser = () => typeof window !== 'undefined';
    const rootNode = isBrowser() ? window.document.getElementById('___gatsby') : undefined;
    const home = isBrowser() ? window.location.pathname === '/' : undefined;
    const getOpacity = () => {
      if (Object.keys(user).length && !user.internal) {
        return ['umbi', 'umd'];
      } else {
        return null;
      }
    };
    return (
    <>
      <header className={headerColor}>
        <div className="header-wrap">
          <div className="app-drawer">
            {this.state.canRender && (
                <AppDrawer
                  notificationCountById={notificationCountById}
                  rootNode={rootNode}
                  hiddenAppsBlogById={hiddenAppsBlogById}
                  umxApp
                  position={{ top: '50px', left: 'auto', right: '30px', bottom: 'auto' }}
                  darkIcon={home ? false : true}
                  opacity={getOpacity()}
                />
            )}
          </div>
          <div className="nav-logo">
            {title && <h5>{title}</h5>}
          </div>
            <div className="nav-top ml-auto">
              {this.state.canRender && displayName ? (
                <div className="hamburger-user">
                  {/* <div className="hamburger-icon" onClick={() => this.setState({ menu: true })}>
                    <img src={menuicon} alt="Menu" />
                  </div> */}
                <div className="user-dropdown">
                  <DropdownButton
                    alignRight
                    title={displayName}
                    id="dropdown-menu-align-right"
                    onClick={() => this.handleShow()}
                    tabIndex={1}
                  >
                  </DropdownButton>
                </div>
              </div>
                ) :
                isBrowser() && location !== "login" && !getCookie("swiftTrends") &&
                  <a href={`${DIZZEE_LOGIN}&client=umx&redirect=${encodeURIComponent(
                    window.location.href
                  )}`} className="sign-in-btn">Sign In</a>}
            </div>
        </div>
      </header>
      <Modal id="subscriptionModal" show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <a href="#" className="close-btn">
            <div className="close-btn-img">
              <img src={closeicon} alt="close" onClick={this.handleClose} />
            </div>
          </a>
          <div className="row">
            <div className="col-md-12">
              <div className="your-account">
                <h6>Your Account</h6>
                <div className="user-detail">
                  <div className="user-detail-group">
                    <small>Full Name</small>
                    <span className="account-detail">{fullName}</span>
                  </div>
                  <div className="user-detail-group">
                    <small>Email</small>
                    <span className="account-detail">{email}</span>
                  </div>
                </div>
                <a href={`${LOGOUT}`} className="signout-link">Sign Out</a>
              </div>
            </div>
            {/* <div className="col-md-7">
              <div className="email-updates">
                <h6>Email Updates</h6>
                <div className="email-update-switch-group">
                  <div className="switch-btn-group">
                    <p>Universal Music Artists</p>
                    <div className="button-switch">
                      <input type="checkbox" id="uma" className="switch" />
                    </div>
                  </div>
                  <div className="switch-btn-group">
                    <p>Universal Music Labels</p>
                    <div className="button-switch">
                      <input type="checkbox" id="uml" className="switch" />
                    </div>
                  </div>
                  <div className="switch-btn-group">
                    <p>Universal Music Discover</p>
                    <div className="button-switch">
                      <input type="checkbox" id="umd" className="switch" />
                    </div>
                  </div>
                  <div className="switch-btn-group">
                    <p>Universal Music Consumption</p>
                    <div className="button-switch">
                      <input type="checkbox" id="umc" className="switch" />
                    </div>
                  </div>
                  <div className="switch-btn-group">
                    <p>Universal Music Business Intelliegence</p>
                    <div className="button-switch">
                      <input type="checkbox" id="umbi" className="switch" />
                    </div>
                  </div>
                  <div className="switch-btn-group">
                    <p>Data Stories</p>
                    <div className="button-switch">
                      <input type="checkbox" id="ds" className="switch" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <div className={menu ? "overlay-bg overlay-bg-show" : "overlay-bg"} onClick={() => this.setState({ menu: false })}></div>
    </>);
  }
}

export default Header;
