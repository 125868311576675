import { getCookie } from "../utlis/cookieMgmt";
// import axios from "axios";
import { DIZZEE_API, DIZZEE_LOGIN, CLIENT_URL } from "gatsby-env-variables";

export const swiftTrendsCookie = getCookie("swiftTrends");
export const DIZZEE_WHOAMI = `${DIZZEE_API}/api/whoami?app=swiftTrends`;

export const isLoggedIn = () => {
  if (swiftTrendsCookie) {
    return true;
  }
  if (!swiftTrendsCookie) {
    return false;
  }
}

export const reloadIfAuthExpired = err => {
  if (err.response && err.response.status === 401) {
    window.location.href = `${DIZZEE_LOGIN}&redirect=`;
  }
};