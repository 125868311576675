import React, { Component } from 'react';
import footerlogo from '../assets/images/UMG-logo.svg';
import emailicon from '../assets/images/ic-email.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import CF from '../components/commonfunctions.js';
import Modal from 'react-bootstrap/Modal';
import closeicon from '../assets/images/close-icon.svg';

class Footer extends Component {

    // const [show, setShow] = useState(false);


    state = {
        email: '',
        error: '',
        show: false
    }


    // componentDidMount() {
    //     this.emailUpdate()
    // }
    emailUpdate = async () => {
        let { email, error } = this.state;
        let wordpress = `${process.env.WORDPRESS_BASEURL}/wp-admin/admin-ajax.php`;
        const data = new FormData();
        data.append('subscription_email', email);
        data.append('subscription_locale', 'en_US');
        data.append('action', 'send_mailjet_subscription_form');
        data.append('widget_id', "wp_mailjet_subscribe_widget-2");
        let response = await CF.callApi(wordpress, data, 'post', true);
        if (response && response.data === "Please provide an email address") {
            error = response && response.data; this.setState({ error });
        }
        else if (response && response.data === "Invalid email") {
            error = response && response.data; this.setState({ error });
        }
        else if (response && response.data === "Subscription confirmation email sent. Please check your inbox and confirm your subscription.") {
            CF.showSuccessMessage(response && response.data);
            this.setState({ error: '', email: "" })
        }

    }
    onTextChanged(e) {
        let { value, name } = e && e.target && e.target;
        let { email } = this.state;
        if (name === "email") email = value;
        this.setState({ email })
    }
    handleClose = () => {
        this.setState({ show: false })
    };
    handleShow = () => {
        this.setState({ show: true })
    };
    render() {
        let { email, error } = this.state;
        return (<React.Fragment>
          {
            // {typeof window !== 'undefined' && (window.location && window.location.pathname !== "/") ? <section id="conatctus" className="subscription-common">
            //     <div className="container" >
            //         <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
            //             <div className="subscription-form">
            //                 <div className="title">
            //                     <span className="email-img">
            //                         <img src={emailicon} alt="Close" />
            //                     </span>
            //                     <h4>RECEIVE OUR UPDATES</h4>
            //                 </div>
            //                 <div className="form-group">
            //                     <input type="text"
            //                         className="form-control"
            //                         id="email"
            //                         name="email"
            //                         placeholder="your email here"
            //                         onChange={(e) => this.onTextChanged(e)}
            //                         value={email}
            //                     ></input>
            //                     {error && <div className="error">
            //                         {error}
            //                     </div>}
            //                 </div>
            //                 <button className="btn btn-brd" type="submit" onClick={() => this.emailUpdate()}><span>SUBMIT</span></button>
            //             </div>
            //         </ScrollAnimation>
            //     </div>
            //
            //     {/* <button type="button" className="btn btn-brd" onClick={this.handleShow}>
            //         <span>Launch demo modal</span>
            //     </button> */}
            //
            //     {/* <Modal id="subscriptionModal" show={this.state.show} onHide={this.handleClose}>
            //
            //         <Modal.Body>
            //             <a href="#" className="close-btn">
            //             <div className="close-btn-img">
            //                 <img src={closeicon} alt="close" />
            //             </div>
            //             </a>
            //             <div className="row">
            //                     <div className="col-md-5">
            //                         <div className="your-account">
            //                             <h6>Your Account</h6>
            //                             <div className="user-detail">
            //                                 <div className="user-detail-group">
            //                                         <small>Full Name</small>
            //                                         <span className="account-detail">John Doe</span>
            //                                 </div>
            //                                 <div className="user-detail-group">
            //                                         <small>Email</small>
            //                                         <span className="account-detail">John.Doe@umusic.com</span>
            //                                 </div>
            //                             </div>
            //                             <a href="#" className="signout-link">Sign Out</a>
            //                         </div>
            //                     </div>
            //                     <div className="col-md-7">
            //                         <div className="email-updates">
            //                             <h6>Email Updates</h6>
            //                             <div className="email-update-switch-group">
            //                                 <div className="switch-btn-group">
            //                                     <p>Universal Music Artists</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="uma" class="switch" />
            //                                     </div>
            //                                 </div>
            //                                 <div className="switch-btn-group">
            //                                     <p>Universal Music Labels</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="uml" class="switch" />
            //                                     </div>
            //                                 </div>
            //                                 <div className="switch-btn-group">
            //                                     <p>Universal Music Discover</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="umd" class="switch" />
            //                                     </div>
            //                                 </div>
            //                                 <div className="switch-btn-group">
            //                                     <p>Universal Music Consumption</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="umc" class="switch" />
            //                                     </div>
            //                                 </div>
            //                                 <div className="switch-btn-group">
            //                                     <p>Universal Music Business Intelliegence</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="umbi" class="switch" />
            //                                     </div>
            //                                 </div>
            //                                 <div className="switch-btn-group">
            //                                     <p>Data Stories</p>
            //                                     <div class="button-switch">
            //                                           <input type="checkbox" id="ds" class="switch" />
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //             </div>
            //
            //         </Modal.Body>
            //
            //     </Modal> */}
            //
            // </section> : ""}
          }
            <footer>
                <a href="/" className="footer-logo">
                    <img src={footerlogo} alt="Close" />
                </a>
            </footer>


        </React.Fragment>);
    }
}

export default Footer;
